import React from 'react';
import PrevNextButtons from '../common/prev-next-buttons';
import { BlogListContext } from '../../types/BlogListContext';

interface OlderNewBlogPostsLinksProps {
  pageContext?: BlogListContext;
}

const OlderNewBlogPostsLinks: React.FC<OlderNewBlogPostsLinksProps> = ({ pageContext }) => {
  if (!pageContext) {
    return <div />;
  }
  const { hasPrevPage, hasNextPage, prevPageLink, nextPageLink } = pageContext;
  return (
    <PrevNextButtons
      nextLabel="Ältere Posts"
      nextTo={hasNextPage && nextPageLink}
      prevLabel="Neuere Posts"
      prevTo={hasPrevPage && prevPageLink}
    />
  );
};

export default OlderNewBlogPostsLinks;
