import React from 'react';
import Button from './custom-button';

interface NextButtonProps {
  to?: string | boolean;
  label?: string;
}

const NextButton: React.FC<NextButtonProps> = ({ to, label }) => {
  if (!to || !label) {
    return <div />;
  }
  return (
    <Button to={`${to}`}>
      {label} <i className="fas fa-angle-double-right" />
    </Button>
  );
};

export default NextButton;
