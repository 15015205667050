import React from 'react';

interface TagIconProps {
  name: string | null;
}

const TagIcon: React.FC<TagIconProps> = ({ name }) => {
  switch (name) {
    case 'Bildstrecke':
      return <i className="far fa-images" />;
    case 'Collage':
      return <i className="fas fa-palette" />;
    default:
      return <span />;
  }
};

export default TagIcon;
