import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/site-layout/site-layout';
import OlderNewerBlogPostsLinks from '../components/blog-post/older-newer-blog-posts-links';
import { BlogListContext } from '../types/BlogListContext';
import { Head } from '../components/site-layout/head';
import BlogList from '../components/blog-post/blog-list';
import { getImage } from 'gatsby-plugin-image';

const BlogListPage: React.FC<PageProps<GatsbyTypes.BlogListPageQueryQuery, BlogListContext>> = ({
  data,
  pageContext,
  location,
}) => {
  const headerImage =
    data.headerImage && data.headerImage.childImageSharp
      ? getImage(data.headerImage.childImageSharp.gatsbyImageData)
      : undefined;
  return (
    <Layout location={location} image={headerImage}>
      <Head title="Blog" />
      <BlogList title="Blog" posts={data.posts.edges.map((egde) => egde.node)}>
        <OlderNewerBlogPostsLinks pageContext={pageContext} />
      </BlogList>
    </Layout>
  );
};

export default BlogListPage;

export const pageQuery = graphql`
  query BlogListPageQuery($skip: Int, $limit: Int) {
    headerImage: file(relativePath: { eq: "images/lp-blog.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 4000)
      }
    }
    posts: allMdx(skip: $skip, limit: $limit, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          ...BlogPostCard
        }
      }
    }
  }
`;
