import React from 'react';

import styled from 'styled-components';
import { Title } from '../common/title';
import BlogPostCard from './blog-post-card';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 3rem;
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  width: 100%;
  max-width: 1920px;
  grid-gap: 3rem;
  align-items: stretch;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }

  @media (max-width: 858px) {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
`;

interface BlogListProps {
  readonly posts: Readonly<GatsbyTypes.BlogPostCardFragment[]>;
  readonly title: string;
}

const BlogList: React.FC<BlogListProps> = ({ posts, title, children }) => (
  <Root>
    <Title>{title}</Title>
    <Wrapper>
      <BlogGrid>
        {posts.map((post, key) => (
          <BlogPostCard key={key} post={post} />
        ))}
      </BlogGrid>
      {children}
    </Wrapper>
  </Root>
);

export default BlogList;
