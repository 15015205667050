import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import FeaturedImage from './featured-image';
import BlogPostMeta from './blog-post-meta';
import { THEME } from '../../constants/theme';

const Root = styled(Link)`
  display: flex;
  flex-direction: column;
  color: ${THEME.dark};
  text-decoration: none;
  border-radius: 0.2rem;
  transition: all 0.2s ease-in-out;
  background-color: ${THEME.middle};
  box-shadow: 2px 2px 5px ${THEME.gray};

  :hover {
    color: ${THEME.gray};
    box-shadow: 6px 6px 12px ${THEME.gray};
    text-decoration: none;
  }
`;

const Details = styled.div`
  padding: 0.5rem 1rem;
`;

const Grow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  * {
    flex: 1;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.4rem;

  @media (max-width: 900px) {
    font-size: 1.2rem;
  }
`;

interface BlogPostCardProps {
  post?: GatsbyTypes.BlogPostCardFragment;
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({ post }) => {
  if (!post || !post.fields || !post.fields?.slug) {
    return <></>;
  }
  const title = post.frontmatter?.title || post.fields?.slug;
  const tags = post.frontmatter?.tags || [];
  return (
    <Root to={post.fields?.slug}>
      <Grow>
        <FeaturedImage image={post.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData} />
      </Grow>
      <Details>
        <Title>{title}</Title>
        <BlogPostMeta date={post.frontmatter?.date} tags={tags} />
      </Details>
    </Root>
  );
};

export default BlogPostCard;

export const query = graphql`
  fragment BlogPostCard on Mdx {
    excerpt
    fields {
      slug
    }
    frontmatter {
      date(formatString: "DD MMMM, YYYY")
      title
      tags
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 800)
        }
      }
    }
  }
`;
