import React from 'react';
import Button from './custom-button';

interface PrevButtonProps {
  to?: string | boolean;
  label?: string;
}

const PrevButton: React.FC<PrevButtonProps> = ({ to, label }) => {
  if (!to || !label) {
    return <div />;
  }
  return (
    <Button to={`${to}`}>
      <i className="fas fa-angle-double-left" /> {label}
    </Button>
  );
};

export default PrevButton;
