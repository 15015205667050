import React from 'react';
import Tag from './blog-tag';
import { Image } from '../../types/Image';
import styled from 'styled-components';

const CreationDate = styled.div`
  font-size: 0.8rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
`;

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface BlogPostCardEntry {
  excerpt: string | null;
  fields: BlogPostCardFields | null;
  frontmatter: BlogPostCardContent | null;
}

interface BlogPostCardFields {
  slug: string | null;
}

interface BlogPostCardContent {
  date: any | null;
  title: string | null;
  tags: (string | null)[] | null;
  featuredImage: Image | null;
}

interface BlogPostMetaProps {
  date: any | null;
  tags?: Readonly<(string | undefined)[]>;
}

const BlogPostMeta: React.FC<BlogPostMetaProps> = ({ date, tags }) => (
  <InfoLine>
    <CreationDate>{date}</CreationDate>
    {tags && tags.map((tag, idx) => <Tag key={idx} tag={tag} />)}
  </InfoLine>
);

export default BlogPostMeta;
