import React from 'react';
import PrevButton from './prev-button';
import NextButton from './next-button';
import styled from 'styled-components';
import { THEME } from '../../constants/theme';

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 1280px;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;

    a {
      width: 100%;
    }
  }

  a {
    border-radius: 0.2rem;
    transition: all 0.2s ease-in-out;
    border-color: ${THEME.dark};
    color: ${THEME.text};
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;

    i {
      margin: 0 0.5rem;
    }
  }

  a:hover {
    opacity: 0.5;
    text-decoration: none;
  }

  a:nth-child(2) {
    justify-content: flex-end;
  }

  a:nth-child(1) {
    justify-content: flex-start;
  }
`;

interface PrevNextButtonsProps {
  nextTo?: string | false;
  nextLabel?: string;
  prevTo?: string | false;
  prevLabel?: string;
}

const PrevNextButtons: React.FC<PrevNextButtonsProps> = ({ nextTo, nextLabel, prevTo, prevLabel }) => (
  <Root>
    <PrevButton to={prevTo} label={prevLabel} />
    <NextButton to={nextTo} label={nextLabel} />
  </Root>
);

export default PrevNextButtons;
