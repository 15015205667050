import React from 'react';
import styled from 'styled-components';

import TagIcon from './blog-tag-icon';

const Root = styled.div`
  font-size: 0.8rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  font-weight: bold;

  i {
    margin-right: 0.3rem;
  }
`;

interface TagProps {
  tag?: string;
}

const Tag: React.FC<TagProps> = ({ tag }) => (
  <>
    {tag !== undefined && (
      <Root>
        <TagIcon name={tag} />
        {tag}
      </Root>
    )}
  </>
);

export default Tag;
