import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledLink = styled(Link)`
    border: 0.1rem solid black;
    margin-top: 1rem;
    padding: 0.5rem;
    width: 12rem;
    text-align: center;
}
`;

interface ButtonProps {
  to: string;
  label?: string;
}

const Button: React.FC<ButtonProps> = ({ to, label, children }) => (
  <StyledLink to={to}>{label ? label : children}</StyledLink>
);

export default Button;
