import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';

interface FeaturedImageProps {
  image?: ImageDataLike;
}

const FeaturedImage: React.FC<FeaturedImageProps> = ({ image }) => {
  let featuredImage: IGatsbyImageData | undefined;
  if (!image || !(featuredImage = getImage(image))) return <div />;
  return <GatsbyImage image={featuredImage} alt={''} />;
};

export default FeaturedImage;
